<template>
  <v-app>
    <!-- <v-main> -->
      <div>
        <router-view/>
      </div>
    <!-- </v-main> -->
  </v-app>
</template>

<script>




export default {
  name: 'App',

};
</script>
