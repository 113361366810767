import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import AppBar from '../views/AppBar.vue'
// import About from '../views/About.vue'
// import PayOut from '../views/PayOut.vue'
// import Ddpi from '../views/DdpiInfo.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'profile',
  },
  {
    path:'/app',
    name: 'App',
    component: AppBar,
    children:[
    // {
    //   path: '/menubar',
    //   name: 'Menubar',
    //   component: () => import(/*MenuBar in appBar */ '../views/MenuBar.vue'),
    //   children:[
        {
          path: '/profile',
          name: 'Profileinfo',
          component: ()=>import ('../views/ProfileInfo.vue')
        },
        {
          path: '/bank',
          name: 'Bankinfo',
          component: ()=>import ('../views/BankInfo.vue')
        },
        {
          path: '/deposltory',
          name: 'Deposltoryinfo',
          component: ()=> import ('../views/DeposltoryInfo.vue')
        },
        {
          path: '/segment',
          name: 'Segmentinfo',
          component: ()=> import ('../views/SegmentInfo.vue')
        },
        {
          path:'/annualincome',
          name:'Annualincomeinfo',
          component: ()=> import ('../views/AnnualincomeInfo.vue')
        },
        {
          path:'/nominee',
          name: 'Nominee',
          component: ()=> import ('../views/NomineeInfo.vue')
        },
        {
          path:'/closure',
          name:'Closure',
          component: ()=> import ('../views/ClosureInfo.vue') 
        },
        {
          path: '/family',
          name:'family',
          component:() => import('../views/FamilyInfo.vue')
        },
        {
          path: '/mtf',
          name:'mtf',
          component:() => import('../views/MtF.vue')
        },
        {
          path: '/refer',
          name:'ReFeral',
          component:() => import('../views/ReFeral.vue')
        },
         {
          path: '/download',
          name:'DownLoad',
          component:() => import('../views/DownLoad.vue')
        },
        // {
        //   path: '/gift',
        //   name:'StockGift',
        //   component:() => import('../views/StockGift.vue')
        // },
        // {
        //   path: '/viewgift',
        //   name:'ReciveGift',
        //   component:() => import('../views/ReciveGift.vue')
        // },
        // {
        //   path: '/fund',
        //   name: 'About',
        //   // route level code-splitting
        //   // this generates a separate chunk (about.[hash].js) for this route
        //   // which is lazy-loaded when the route is visited.
        //   component: About
        // },
        // {
        //   path: '/withdrawal',
        //   name: 'withdrawal',
        //   component: PayOut
        // },
        // {
        //   path: '/ddpi',
        //   name:'Ddpi',
        //   component:Ddpi
        // }
      // ]
    // }
    ]
  },
  // {
  //   path: '/profiles',
  //   name: 'Profile',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/ProfilE.vue')
  // },
  {
    path: '/request',
    name: 'request',
    component: () => import(/* webpackChunkName: "about" */ '../views/RequestPage.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
